import React from "react";
import { activitiesAtom, useAtom } from "~/atoms";

export function useActivities() {
  const [activities, setActivities] = useAtom(activitiesAtom);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    fetch("/api/activities")
      .then((res) => res.json())
      .then((res) => setActivities(res.activities))
      .finally(() => setLoading(false));
  }, [setActivities]);

  return {
    activities,
    loading,
  };
}
